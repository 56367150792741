<template>
  <div style="width: 100%; height: 100%">
    <div v-if="isLoading" class="myloading">
      <van-loading type="spinner" />
    </div>
    <div v-else class="box">
      <div class="title">阳春数字精酿智慧运营平台</div>
      <div class="content">
        <div>
          <div class="con-login">
            <van-form ref="vanform" label-width="3em">
              <div class="padding">
                <van-field
                  v-model="form.username"
                  :rules="rules.username"
                  label="用户名"
                  placeholder="请输入用户名"
                ></van-field>
              </div>
              <div class="padding">
                <van-field
                  v-model="form.password"
                  :rules="rules.password"
                  type="password"
                  label="密码"
                  placeholder="请输入密码"
                ></van-field>
              </div>
            </van-form>
            <div class="btn-warrper">
              <van-button round block type="info" @click="handleLogin"
                >提交</van-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Notify } from "vant";
import { login, userInfo, codetotoken } from "@/api/login";
export default {
  name: "Login",
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      isLoading: false,

      passwordType: "password",
      rules: {
        username: [{ required: true, message: "" }],
        password: [
          { required: true, message: "" },
          // {
          //   required: true,
          //   trigger: "blur",
          //   validator: (rule, value, callback) => {
          //     if (!(value.length >= 6)) callback(new Error("密码不能少于6位"));
          //     else callback();
          //   },
          // },
        ],
      },
    };
  },

  computed: {},
  watch: {
    $route: {
      handler(route) {
        this.redirect = (route.query && route.query.redirect) || "/";
      },
      immediate: true,
    },
  },
  mounted() {
    var code = this.getUrlparam("code");
    if (code) {
      this.isLoading = true;
      codetotoken({ code: code })
        .then((res) => {
          this.isLoading = false;
          localStorage.setItem("token", res.data.token);
          console.log(window.location)
          // origin hash
          this.$router.push("/app/home");
          // window.location.href = window.location.origin + '/' + window.location.hash
          Notify({ type: "success", message: "登录成功" });
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          // Notify({ type: "danger", message: err.response.data.msg });
        });
    }
  },
  methods: {
    getUrlparam(variable) {
      var url = window.location.href.split("?");
      var query = window.location.href.split("?")[url.length - 1];
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return null;
    },
    handleLogin() {
      this.$refs.vanform
        .validate()
        .then(() => {
          this.isLoading = true;
          login({
            username: this.form.username,
            password: this.form.password,
            verificationCode: "APP",
          })
            .then((res) => {
              console.log(res);
              this.isLoading = false;
              localStorage.setItem("token", res.data.token);
              this.$router.push("/app/home");
              Notify({ type: "success", message: "登录成功" });
            })
            .catch((err) => {
              console.log(err);
              this.isLoading = false;
              // Notify({ type: "danger", message: err.response.data.msg });
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.padding {
  padding: 0.625rem;
}
.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  .title {
    width: 100%;
    height: 2rem;
    flex-shrink: 0;
    background-color: #fa6401;
    color: #fff;
    font-size: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content {
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
    background-color: #f3ccb2;
  }
  .content > div {
    width: 100%;
    height: 100%;
    padding: 0.9375rem;
    display: flex;
    align-items: center;
  }
}

.con-login {
  padding: 0.9375rem;
  width: 100%;
  background: url("~@/assets/login_images/login_form.png") no-repeat;
  background-size: 100% 100%;
}
</style>
